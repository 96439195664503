import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/store";

Vue.config.productionTip = true;
Vue.config.productionTipPython = true;
Vue.config.productionTipHash = true;

Vue.prototype.$hostnamejava = Vue.config.productionTip
  ? process.env.VUE_APP_TARGET_JAVA_DOMAIN
  : "http://localhost:8021";
Vue.prototype.$hostnamefront = Vue.config.productionTip
  ? process.env.VUE_APP_TARGET_FRONT_HOSTNAME
  : "http://localhost:8080";
Vue.prototype.$hostname = Vue.config.productionTipPython
  ? process.env.VUE_APP_TARGET_API_DOMAIN
  : "http://localhost:8282";
Vue.prototype.$hostname_auth = Vue.config.productionTipPython
  ? process.env.VUE_APP_TARGET_API_AUTH_DOMAIN
  : "http://localhost:8282";
Vue.prototype.$hostname_ba = Vue.config.productionTipPython
  ? process.env.VUE_APP_TARGET_API_BA_DOMAIN
  : "http://localhost:8282";
Vue.prototype.$hostname_ba_macro = Vue.config.productionTipPython
  ? process.env.VUE_APP_TARGET_API_BA_MACRO_DOMAIN
  : "http://localhost:8285";
Vue.prototype.$auth_enabled = process.env.VUE_APP_AUTH_ENABLED == "true";
Vue.prototype.$is_secure = process.env.VUE_APP_IS_SECURE == "true";

import "../public/css/materialdesignicons.min.css";
import "swiper/swiper-bundle.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { BootstrapVue } from "bootstrap-vue";
var VueScrollTo = require("vue-scrollto");

import Scrollspy from "vue2-scrollspy";
import VueYoutube from "vue-youtube";

Vue.use(VueScrollTo, {
  duration: 3000,
  easing: "ease",
});

Vue.use(BootstrapVue);
Vue.use(Scrollspy);
Vue.use(VueYoutube);

import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";
Vue.use(Vuesax);

import VueCookies from "vue-cookies";

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, {
  sameSite: Vue.prototype.$is_secure ? "None" : "Lax",
  secure: Vue.prototype.$is_secure,
});

import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.withCredentials = true;

// Не удаляй - здесь мы можем добавить MITM во все запросы axios; Может быть полезно
// axios.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     console.log('inside request intercept');
//     console.log(config);
//     return config;
//   }, function (error) {
//     console.log('inside request err intercept');
//     console.log(error);
//     // Do something with request error
//     return Promise.reject(error);
//   });
// axios.interceptors.response.use(function (response) {
//     console.log('inside response intercept');
//     console.log(response);
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   }, function (error) {
//     console.log('inside response error intercept');
//     console.log(error);
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   });

Vue.use(VueAxios, axios);
import "material-icons/iconfont/material-icons.css";

import VueWordCloud from "vuewordcloud";
Vue.component(VueWordCloud.name, VueWordCloud);

import TextHighlight from "vue-text-highlight";
Vue.component("text-highlight", TextHighlight);

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

import Notifications from "vue-notification";
Vue.use(Notifications);

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
Vue.component("VueSlider", VueSlider);

import VueCoreVideoPlayer from "vue-core-video-player";
Vue.use(VueCoreVideoPlayer);

import VueQuillEditor from "vue-quill-editor";

import "quill/dist/quill.core.css";
//import 'quill/dist/quill.snow.css';
import "../public/css/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor);

import VueTour from "vue-tour";
import "vue-tour/dist/vue-tour.css";
Vue.use(VueTour);

import AudioRecorder from "vue-audio-recorder";
Vue.use(AudioRecorder);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

import VueSSE from "vue-sse";
Vue.use(VueSSE);

import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);

router.beforeEach((to, from, next) => {
  if (!Vue.prototype.$auth_enabled) {
    next();
  }
  let destinationPath = to.path.split("/").filter((x) => x.length > 0)[0];
  destinationPath = destinationPath == void 0 ? "/" : "/" + destinationPath;
  let ignore_auth_paths = ["/", "/auth/login", "/register", "/auth"];
  if (!ignore_auth_paths.includes(destinationPath)) {
    Vue.prototype
      .axios({
        method: "post",
        url: "" + Vue.prototype.$hostname_auth + "/auth/auth2_front",
        data: {
          route: destinationPath,
          user_id: Vue.prototype.$cookies.get("id_sml"),
          access_token: Vue.prototype.$cookies.get("access_token_sml"),
        },
      })
      .then((res) => {
        console.log("auth passed");
        next();
      })
      .catch((err) => {
        console.log("ERR");
        if (err.response.status === 403) {
          next("/?error_code=" + err.response.status);
        } else {
          next("auth/login?error_code=" + err.response.status);
        }
      });
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
